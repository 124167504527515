import * as React from "react"
import styled, { css } from "styled-components"
import Icon from "../shared/icon"
import Google from "../../images/badges/google.svg"
import Apple from "../../images/badges/apple.svg"
import { sitemap } from "../../data/sitemap"
import { socials } from "../../data/socials"
import { vw } from "../../utilities/pxtovw"
import Overline from "../shared/overline"
import { useGlobalStateContext } from "../../utilities/context"
import UgFlag from "../../images/Flag_of_Uganda.svg.png"
import KeFlag from "../../images/Flag_of_Kenya.svg.png"

const Box = styled.footer`
  position: relative;
  padding: ${vw(172)} 5rem ${vw(84)};
  font-size: 0.875rem;
  display: flex;

  .xn-pattern {
    position: absolute;
    height: ${vw(42)};
    width: ${vw(108)};
    top: ${vw(92)};
  }

  ul,
  p {
    line-height: 1.8;
  }

  ul {
    margin-bottom: ${vw(36)};
  }

  .call {
    a {
      padding: ${vw(30)} 0;
      display: block;
    }
  }

  h2 {
    font-family: var(--xn-font-body);
    font-style: normal;
    font-weight: bold;
    font-size: 2rem;
    line-height: 2.7rem;
    padding-bottom: ${vw(30)};
    letter-spacing: -0.75px;
    position: relative;
    top: -0.5rem;

    span {
      color: var(--c-mint-100);
    }
  }

  h3 {
    padding-bottom: ${vw(40)};

    span {
      color: var(--c-mint-100);
      opacity: 1;
      font-weight: bold;
    }
  }

  @media (prefers-color-scheme: light) {
    h2,
    h3 {
      span {
        color: var(--c-green-200);
        opacity: 1;
      }
    }
  }

  @media (max-width: 766px) {
    padding: 2rem;
    display: block;
    padding-top: 7rem;
  }
`

const Badges = styled.div`
  display: flex;
  padding-top: ${vw(56)};

  a {
    display: block;
    width: ${vw(136)};
    height: ${vw(37)};
    position: relative;
  }

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
  }

  @media (max-width: 766px) {
    padding-top: 3rem;

    a {
      width: ${vw(400)};
      height: ${vw(108)};
    }
  }
`

const Group = styled.div`
  width: 50%;
  ${props =>
    props.fullWidth &&
    css`
      width: 100%;
    `}

  ${props =>
    props.center &&
    css`
      padding: 0 6.5625vw;
      padding-bottom: 3rem;
    `}
`

const Links = styled.div`
  a {
    display: block;
    padding: 0.25rem 0;
    transition: color 0.3s;
    &:hover {
      color: var(--c-mint-100);
    }
  }
`

const Networks = styled.div`
  display: flex;
  padding-top: ${vw(30)};

  a {
    margin-right: 1.5vw;
    height: ${vw(25)};
    width: ${vw(25)};
    background-color: var(--c-mint-100);
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 766px) {
      height: 1.7rem;
      width: 1.7rem;
    }
  }

  svg {
    width: 50%;
    height: 50%;

    path {
      fill: var(--c-blue-200);
    }
  }
`

const Left = styled.div`
  flex: 1.5;

  .wrap {
    padding-right: ${vw(129)};
  }

  @media (max-width: 766px) {
    padding-bottom: 5rem;
  }
`

const Right = styled.div`
  flex: 2;
  display: flex;
  flex-wrap: wrap;
`

const Legal = styled.div`
  font-size: 0.75rem;
  width: 100%;
  padding: 0 5rem ${vw(84)};
  display: flex;
  justify-content: space-between;

  p {
    padding-bottom: 1.5rem;
    display: flex;

    &.center {
      text-align: center;
      display: block;
    }
  }

  .country-flag {
    width: 1.8rem;
    height: 1.8rem;
    overflow: hidden;
    border-radius: 100%;
    margin-bottom: 0.5rem;
    flex-shrink: 0;
    margin-right: 1rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media (max-width: 766px) {
    padding: 2rem;
    padding-bottom: 6rem;

    .country-flags {
      padding-left: 2rem;
    }
  }

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    .country-flags {
    }
  }
`

const addUnderscores = text => {
  return text.replace(/ /g, "_")
}

const Ele = ({ theme }) => {
  const { countryCode } = useGlobalStateContext()
  const [initialDeposit, setInitialDeposit] = React.useState("UGX 10,000")

  let date = new Date()
  let currentYear = date.getFullYear()
  const [waLink, setWaLink] = React.useState(
    "https://web.whatsapp.com/send?phone=254794229922&text&app_absent=0"
  )

  React.useEffect(() => {
    if (window.innerWidth < 768) {
      setWaLink("https://wa.me/+254794229922")
    }
  }, [])

  React.useEffect(() => {
    switch (countryCode) {
      case "UG":
        setInitialDeposit("UGX 10,000")
        break

      case "KE":
        setInitialDeposit("KES 500")
        break

      default:
        break
    }
  })

  //return
  return (
    <>
      <Box className={theme}>
        <Left>
          <div className="wrap">
            <h2>
              Start from as little as <br />
              <span>{initialDeposit}</span>
            </h2>
            <p>
              Saving money is an excellent first step toward financial freedom.
              Start your journey today.
            </p>
            <Badges>
              <a
                href="https://play.google.com/store/apps/details?id=com.xeno.investment&showAllReviews=true"
                rel="noreferrer"
                target="_blank"
                className="google"
                id="app_download_link__google_play"
              >
                <img src={Google} alt="" />
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                className="apple"
                href="https://apps.apple.com/ug/app/xeno-investment/id1572436933#?platform=iphone"
                id="app_download_link__apple_store"
              >
                <img src={Apple} alt="" />
              </a>
            </Badges>
          </div>
        </Left>
        <Right>
          <Group>
            <h3>
              <Overline>Explore</Overline>
            </h3>
            <Links>
              {sitemap &&
                sitemap.map((section, index) => {
                  return (
                    <ul key={index}>
                      {section &&
                        section.map((link, key) => {
                          return (
                            <li key={key}>
                              <a
                                href={link.slug}
                                id={
                                  "footer__" +
                                  addUnderscores(link.label.toLowerCase()) +
                                  "_link"
                                }
                              >
                                {link.label}
                              </a>
                            </li>
                          )
                        })}
                    </ul>
                  )
                })}
            </Links>
          </Group>
          <Group>
            <h3>
              <Overline>Connect</Overline>
            </h3>
            {countryCode === "UG" && (
              <p>
                1st Floor, Workers House, <br />1 Pilkington Road, <br />
                Kampala, Uganda{" "}
              </p>
            )}

            {countryCode === "KE" && (
              <p>
                Vienna Court, State House Crescent Road, 3rd Floor, West Wing,
                Nairobi
              </p>
            )}

            <p className="call">
              <a
                rel="noreferrer"
                target="_blank"
                href={waLink}
                id="footer__contact_link"
              >
                Call/Whatsapp: +254794229922
              </a>
            </p>

            <Networks>
              {socials &&
                socials.map((item, key) => {
                  return (
                    <a
                      href={item.url}
                      rel="noreferrer"
                      target="_blank"
                      key={key}
                      id={
                        "social_media__" +
                        addUnderscores(item.title.toLowerCase()) +
                        "_link"
                      }
                    >
                      <Icon title={item.icon} />
                    </a>
                  )
                })}
            </Networks>
          </Group>
        </Right>
      </Box>
      <Legal>
        <div className="legal-statement">
          <p className="country-section">
            <div className="country-flag">
              <img src={KeFlag} alt="" />
            </div>
            In Kenya, MyXENO Investment Management Limited is licensed and
            regulated as a Fund Manager by Kenya's Capital Markets Authority.
            XENO executes all client portfolios using the following regulated
            unit trust funds: XENO Kenya Money Market Fund, XENO Kenya Bond
            Fund, XENO Kenya East Africa Equity Fund.
          </p>
          <p className="country-section">
            <div className="country-flag">
              <img src={UgFlag} alt="" />
            </div>
            In Uganda, XENO Investment Management Limited is licensed and
            regulated as a Fund Manager by Uganda’s Capital Markets Authority
            and by the Uganda Retirement Benefits Regulatory Authority. XENO
            executes all client portfolios using the following regulated unit
            trust funds: XENO Uganda Money Market Fund, XENO Uganda Bond Fund,
            XENO Uganda Domestic Equity Fund, XENO Uganda Regional Equity Fund.
          </p>
          <p className="center">
            Copyright © {currentYear} XENO Corporation. All rights reserved.
          </p>
        </div>
        <div className="country-flags"></div>
      </Legal>
      {/* <Fonts /> */}
    </>
  )
}
export default Ele
