import React from "react"
import styled from "styled-components"
import Button from "../shared/button"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

const Box = styled.div`
  &.padd-top-large {
    padding-top: 4.5rem;
  }

  &.padd-top-med {
    padding-top: 2.5rem;
  }

  &.inline {
    display: inline-block;
  }

  &.dark {
    a,
    button {
      background-color: #000;
      color: #fff;
      border-radius: 3px;
    }
  }

  @media screen and (max-width: 766px) {
    padding-bottom: 3.5rem;
  }
`
const Ele = ({ className, title, section }) => {
  const logEvent = label => {
    if (typeof window !== "undefined") {
      trackCustomEvent({
        // string - required - The object that was interacted with (e.g.video)
        category: section ? label + " - " + section : label,
        // string - required - Type of interaction (e.g. 'play')
        action: "Click",
        // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
        label: "Group Investment Campaign",
        // number - optional - Numeric value associated with the event. (e.g. A product ID)
        value: 1,
      })
    }
  }
  return (
    <Box className={className}>
      <Button
        label={title !== "" ? title : "Get Started"}
        click={() => {}}
        to="https://join.myxeno.com/?country=KE&group=CLUB"
      ></Button>
    </Box>
  )
}
export default Ele
