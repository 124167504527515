export const sitemap = [
  [
    {
      label: "Company",
      slug: "https://invest.myxeno.com/company",
    },
    {
      label: "Terms of use",
      slug: "/terms-of-use",
    },
    {
      label: "Privacy Policy",
      slug: "/privacy-policy",
    },
    {
      label: "Client Agreement",
      slug: "/client-agreement",
    },
  ],
]
